import { Commit, Dispatch } from 'vuex';
import { AdminUser } from '@/types';
import { SET_ADMIN_USERS } from './mutations';

import { getAdminUsers, postAdminUser } from '@/api';

export default {
  setAdminUsers: async ({ commit }: { commit: Commit }) => {
    const adminUsers = await getAdminUsers();
    commit(SET_ADMIN_USERS, adminUsers);
  },
  createNewAdminUser: async ({ dispatch }: { dispatch: Dispatch }, adminUser: AdminUser) => {
    const response = await postAdminUser(adminUser);
    console.log('CREATE NEW ADMIN USER', response);
    dispatch('setAdminUsers');
  },
};
