import Vue from 'vue';
import Buefy from 'buefy';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUp,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faPlus,
  faMinus,
  faCalendar,
  faEdit,
  faUpload,
  faTag,
  faSync,
  faDownload,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import amplifyConfig from './amplifyConfig';

import App from './App.vue';
import store from './store';
import router from './router';

Amplify.configure(amplifyConfig);

library.add(
  faArrowUp,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faPlus,
  faMinus,
  faCalendar,
  faEdit,
  faUpload,
  faTag,
  faSync,
  faDownload,
  faExclamationCircle
);
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
