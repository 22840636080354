import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Auth } from 'aws-amplify';

import Home from '../views/Home.vue';
import Admins from '../views/Admins.vue';
import Users from '../views/Users.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/admins',
    name: 'admins',
    component: Admins,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: (_to, _from, next) => {
      Auth.currentAuthenticatedUser()
        .then(() => next({ name: 'admins' }))
        .catch(() => next());
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Handling AuthN
router.beforeEach((to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => next())
      .catch(() => next({ name: 'login' }));
  }
  next();
});

export default router;
