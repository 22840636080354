






























































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { patchUser, getUser } from '@/api';

export default Vue.extend({
  name: 'Users',
  data() {
    return {
      isUserCreationModalVisible: false,
      isUserUpdateModalVisible: false,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSort: 'name',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 5,
      newUserGivenName: '',
      newUserFamilyName: '',
      newUserMail: '',
      newUserPhoneNumber: '',
      newUserGroup: 'No',
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters('users', ['users']),
  },
  async mounted() {
    await this.setUsers();
  },
  methods: {
    ...mapActions('users', ['setUsers', 'createNewUser']),
    async onUserInfoCTAClick(userUuid: string) {
      const selectedUser = await getUser(userUuid);
      selectedUser.isLawyerLabel = selectedUser.groups.find((group: string) => group === 'special') ? 'Yes' : 'No';
      this.selectedUser = selectedUser;
      this.isUserUpdateModalVisible = true;
    },
    async onUserGroupToggle(isLawyer: 'Yes' | 'No', userUuid: string) {
      this.$buefy.toast.open({ queue: true, message: `Updating user status...` });
      try {
        await patchUser(userUuid, { groups: isLawyer === 'Yes' ? ['special'] : [] });
        this.$buefy.toast.open({ queue: true, type: 'is-success', message: `User updated succesfully!` });
      } catch (error) {
        this.$buefy.toast.open({
          queue: true,
          type: 'is-danger',
          message: `An error occurred while updating user status. Try again later!`,
        });
        console.error(error);
      }
    },
    async onUserEnableToggle(isEnabled: boolean, userUuid: string) {
      this.$buefy.toast.open({ queue: true, message: `Updating user status...` });
      try {
        await patchUser(userUuid, { enabled: isEnabled });
        this.$buefy.toast.open({ queue: true, type: 'is-success', message: `User updated succesfully!` });
      } catch (error) {
        this.$buefy.toast.open({
          queue: true,
          type: 'is-danger',
          message: `An error occurred while updating user status. Try again later!`,
        });
        console.error(error);
      }
    },
    async onUserCreationModalCTAClick(e: Event) {
      e.preventDefault();
      // @ts-ignore
      if (this.$refs.newUserForm.checkValidity() === true) {
        this.$buefy.toast.open({ queue: true, message: `Creating new user...` });
        try {
          const newUser = {
            givenName: this.newUserGivenName,
            familyName: this.newUserFamilyName,
            mail: this.newUserMail,
            phoneNumber: `+39${this.newUserPhoneNumber}`,
            groups: this.newUserGroup === 'No' ? [] : ['special'],
          };

          await this.createNewUser(newUser).then(() => {
            this.isUserCreationModalVisible = false;
            // Reset user data
            this.newUserGivenName = '';
            this.newUserFamilyName = '';
            this.newUserMail = '';
            this.newUserPhoneNumber = '';
            this.newUserGroup = 'No';
            this.$buefy.toast.open({ queue: true, type: 'is-success', message: `New user created succesfully!` });
          });
        } catch (error) {
          console.log('ERROR', error);
          this.$buefy.toast.open({
            queue: true,
            type: 'is-danger',
            message: `An error occurred while creating a new user. Try again later!`,
          });
        }
      }
    },
  },
});
