



















import Vue from 'vue';
import { mapActions } from 'vuex';
import { Hub, Auth } from 'aws-amplify';

import Navbar from '@/components/Navbar.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Navbar,
  },
  created() {
    // Listen on auth events
    Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          console.log('SIGNED IN HUB');
          // this.isUserSignedIn = true;
          this.$router.push({ name: 'admins' });
          break;
        case 'signOut':
        case 'signIn_failure':
          console.log('SIGNED out / failure HUB');
          // this.isUserSignedIn = false;
          this.$router.push({ name: 'login' });
          break;
        case 'configured':
          try {
            await Auth.currentAuthenticatedUser();
            // this.isUserSignedIn = true;

            console.log('CONFIGURED HUB');
          } catch (e) {
            // this.signedIn = false
            // this.isUserSignedIn = false;
            console.log('ERROR', e);
          }
      }
    });
  },
  async mounted() {
    try {
      console.log('APP MOUNTED');
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      // const session = await Auth.currentSession();

      this.setUser(authenticatedUser);
      // this.$router.push({ name: 'admins' });
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('user', ['setUser']),
  },
});
