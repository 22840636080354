import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';
import users from './users';
import adminUsers from './adminUsers';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    users,
    adminUsers,
  },
});
