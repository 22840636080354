








































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { patchAdminUser } from '@/api';

export default Vue.extend({
  name: 'Admins',
  data() {
    return {
      isAdminUserCreationModalVisible: false,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSort: 'name',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 5,
      newAdminGivenName: '',
      newAdminFamilyName: '',
      newAdminMail: '',
      newAdminPhoneNumber: '',
    };
  },
  computed: {
    ...mapGetters('adminUsers', ['adminUsers']),
  },
  async mounted() {
    await this.setAdminUsers();
  },
  methods: {
    ...mapActions('adminUsers', ['setAdminUsers', 'createNewAdminUser']),
    async onAdminUserEnableToggle(isEnabled: boolean, adminUuid: string) {
      this.$buefy.toast.open({ queue: true, message: `Updating admin status...` });
      try {
        await patchAdminUser(adminUuid, { enabled: isEnabled });
        this.$buefy.toast.open({ queue: true, type: 'is-success', message: `Admin status updated succesfully!` });
      } catch (error) {
        this.$buefy.toast.open({
          queue: true,
          type: 'is-danger',
          message: `An error occurred while updating admin status. Try again later!`,
        });
      }
    },
    async onAdminUserCreationModalCTAClick(e: Event) {
      e.preventDefault();
      // @ts-ignore
      if (this.$refs.newAdminForm.checkValidity() === true) {
        this.$buefy.toast.open({ queue: true, message: `Creating new admin user...` });
        try {
          const newAdmin = {
            givenName: this.newAdminGivenName,
            familyName: this.newAdminFamilyName,
            mail: this.newAdminMail,
            phoneNumber: `+39${this.newAdminPhoneNumber}`,
          };

          await this.createNewAdminUser(newAdmin).then(() => {
            this.isAdminUserCreationModalVisible = false;
            // Reset admin data
            this.newAdminGivenName = '';
            this.newAdminFamilyName = '';
            this.newAdminMail = '';
            this.newAdminPhoneNumber = '';
            this.$buefy.toast.open({ queue: true, type: 'is-success', message: `New admin created succesfully!` });
          });
        } catch (error) {
          this.$buefy.toast.open({
            queue: true,
            type: 'is-danger',
            message: `An error occurred while creating new admin. Try again later!`,
          });
        }
      }
    },
  },
});
