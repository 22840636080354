import { API } from 'aws-amplify';
import { AdminUser, Patch, User } from '@/types';

export const getAdminUsers = async () => {
  try {
    const adminUsers = await API.get(process.env.VUE_APP_AWS_API_NAME, '/admin/admins', {});
    return adminUsers;
  } catch (error) {
    console.error(error);
  }
};

export const postAdminUser = async (adminUser: AdminUser) => {
  try {
    const response = await API.post(process.env.VUE_APP_AWS_API_NAME, '/admin/admins', { body: adminUser });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const patchAdminUser = async (adminUuid: string, payload: Patch<AdminUser>) => {
  try {
    const response = await API.patch(process.env.VUE_APP_AWS_API_NAME, `/admin/admins/${adminUuid}`, { body: payload });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUsers = async () => {
  try {
    const users = await API.get(process.env.VUE_APP_AWS_API_NAME, '/admin/users', {});
    return users;
  } catch (error) {
    console.error(error);
  }
};

export const postUser = async (user: User) => {
  try {
    const response = await API.post(process.env.VUE_APP_AWS_API_NAME, '/admin/users', { body: user });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUser = async (userUuid: string) => {
  try {
    const response = await API.get(process.env.VUE_APP_AWS_API_NAME, `/admin/users/${userUuid}`, {});
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const patchUser = async (userUuid: string, payload: Patch<User>) => {
  try {
    const response = await API.patch(process.env.VUE_APP_AWS_API_NAME, `/admin/users/${userUuid}`, { body: payload });
    return response;
  } catch (error) {
    console.error(error);
  }
};
