import { Commit, Dispatch } from 'vuex';
import { User } from '@/types';
import { SET_USERS } from './mutations';

import { getUsers, postUser } from '@/api';

export default {
  setUsers: async ({ commit }: { commit: Commit }) => {
    const users = await getUsers();
    commit(SET_USERS, users);
  },
  createNewUser: async ({ dispatch }: { dispatch: Dispatch }, user: User) => {
    await postUser(user);
    dispatch('setUsers');
  },
};
