








































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Navbar',
  computed: {
    ...mapGetters('user', ['user']),
  },
});
